import * as React from "react";

import { graphql } from "gatsby";
import Seo from "../components/Seo/seo";
import Landing from "../components/Landing/Landing";

const IndexPage = ({ data }) => {
  return (
    <>
      <Seo title="Home" description="Homepage for Goriot" />
      <Landing landingimages={data.landingimages} />
    </>
  );
};

export const query = graphql`
  query {
    landingimages: allFile(
      filter: { relativeDirectory: { eq: "landing" } }
      sort: { order: ASC, fields: name }
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(
              width: 750
              blurredOptions: { width: 5 }
              placeholder: NONE
              quality: 100
            )
          }
          name
        }
      }
    }
  }
`;

export default IndexPage;
