import React, { useState } from "react";
import * as classes from "./Landing.module.css";

import SixPanel from "../../svg/SixPanel.svg";

const Landing = () => {
  const [clicked, setClicked] = useState(false);

  const clickHandler = () => {
    setClicked((x) => !x);
  };

  const classFilterOn = clicked ? classes.filterOn : "";

  return (
    <div className={classes.landing}>
      <div
        className={`${classes.fixedCentered} ${classes.textClickHere} ${classFilterOn}`}
      >
        Click Here
      </div>
      <div
        className={`${classes.text} ${classFilterOn} ${classes.fixedCentered}`}
        onClick={clickHandler}
      >
        AND THE END OF ALL OUR EXPLORING. WILL BE TO ARRIVE WHERE WE STARTED
        <div>
          LITTLE GIDDING
          <br />
          T.S. ELIOT
        </div>
      </div>
      <div
        className={`${classes.sixpanelContainer} ${classFilterOn}`}
        onClick={clickHandler}
      >
        <SixPanel />
        <SixPanel />
      </div>
      <div className={`${classes.overlay}`} />
    </div>
  );
};

export default Landing;
